export enum ArAdvanceStatus {
  None = 'none',
  Placed = 'placed',
  InReview = 'inReview',
  Approved = 'approved',
  NotApplied = 'NotApplied',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum DisclosureType {
  DAF = 'DAF',
  UAF = 'UAF',
}

// values to be reviewed
export enum ArAdvanceEarlyPaymentDiscountDays {
  None = 'none',
  TD10 = 'TD10',
  EOM10 = 'EOM10',
}

// values to be reviewed
export enum AdvancePaymentDay {
  TD0 = 'TD0',
  TD1 = 'TD1',
  TD3 = 'TD3',
}

export enum PaymentDelayCode {
  TD30 = 'TD30',
  TD45 = 'TD45',
  TD60 = 'TD60',
  TD90 = 'TD90',
  TD120 = 'TD120',
  EOM10 = 'EOM10',
  EOM30 = 'EOM30',
  EOM45 = 'EOM45',
  EOM60 = 'EOM60',
}

type IPaymentDelayCodeMetadata = {
  days: number
  base: 'TD' | 'EOM'
}

export class PaymentDelayCodeMetadata {
  private static data: {
    [key in PaymentDelayCode]?: IPaymentDelayCodeMetadata
  } = {
    [PaymentDelayCode.TD30]: { days: 30, base: 'TD' },
    [PaymentDelayCode.TD45]: { days: 45, base: 'TD' },
    [PaymentDelayCode.TD60]: { days: 60, base: 'TD' },
    [PaymentDelayCode.TD90]: { days: 90, base: 'TD' },
    [PaymentDelayCode.TD120]: { days: 120, base: 'TD' },
    [PaymentDelayCode.EOM10]: { days: 10, base: 'EOM' },
    [PaymentDelayCode.EOM30]: { days: 30, base: 'EOM' },
    [PaymentDelayCode.EOM45]: { days: 45, base: 'EOM' },
    [PaymentDelayCode.EOM60]: { days: 60, base: 'EOM' },
  }

  static get(code: PaymentDelayCode): IPaymentDelayCodeMetadata | undefined {
    return this.data[code]
  }

  static format(code: PaymentDelayCode): string {
    const metadata = this.get(code)
    if (!metadata) return ''

    return metadata.base === 'TD'
      ? `True ${metadata.days}`
      : metadata.base === 'EOM'
      ? `EOM + ${metadata.days}`
      : ''
  }
}
