export enum CreditApplicationStatus {
  New = 'new',
  Processing = 'processing',
  Approved = 'approved',
  Rejected = 'rejected',
  Canceled = 'canceled',
  Review = 'review',
  SentBack = 'sentback',
  Processed = 'processed',
  ExecutionFailed = 'executionfailed',
}
