import { ConnectionType } from '../dictionaries'
import { CreateCardRequest, CreateCardResponse } from '../dtos'
import { delete_, get, post } from '../helpers/caller'
import { IInstitutionModel, ILoanPaymentPlanModel } from '../mst'

export const company = {
  domain: 'company',
  info() {
    return get(company.domain, company.info)
  },
  updateInfo(data: any) {
    return post(company.domain, company.info, data)
  },
  creditInfo() {
    return get(company.domain, company.creditInfo)
  },
  inHouseCreditInfo(param: any) {
    return get(company.domain, company.inHouseCreditInfo, { ...param })
  },
  isFactoringEnabled(param: any) {
    return get(company.domain, company.isFactoringEnabled, { ...param })
  },

  tradeCreditDrawsInfo() {
    return get(company.domain, company.tradeCreditDrawsInfo)
  },
  bankAccounts(param?: any) {
    return get<{
      bankAccounts: {
        name: string
        accountholderName: string
        routingNumber: string
        accountNumber: string
        isManualEntry: boolean
        paymentMethodType: string
        billingAddress?: any
        isPrimary?: boolean
        accountType: string
        finicity?: any
        status: 'verified' | 'notverified'
        _id: string
        id: string
        cardMetadata?: any
        connectionType: ConnectionType
      }[]
    }>(company.domain, company.bankAccounts, { ...param }, true)
  },
  allInstitutions({
    search,
    limit,
    onlyFinicity,
  }: {
    search: string
    limit: number
    onlyFinicity: boolean | 'false'
  }) {
    return get<{
      items: (IInstitutionModel & { _id: string })[]
      result: string
    }>(
      company.domain,
      company.allInstitutions,
      { search, limit, onlyFinicity },
      true,
    )
  },
  bankAccount(id: string) {
    return get(company.domain, company.bankAccount, { id })
  },
  addBankAccount(bankAccount: any) {
    return post(company.domain, company.bankAccount, bankAccount)
  },
  removeBankAccount(id: string) {
    return delete_(company.domain, company.bankAccount, { id })
  },
  addPaymentCard(data: CreateCardRequest) {
    return post<CreateCardResponse>(
      company.domain,
      company.addPaymentCard,
      data,
      {},
      true,
    )
  },
  settings(data: any) {
    return post(company.domain, company.settings, data)
  },
  employees() {
    return get(company.domain, company.employees)
  },
  checkInvoicesToPay() {
    return get(company.domain, company.checkInvoicesToPay)
  },
  userUpsert(data: any) {
    return post(company.domain, company.userUpsert, data)
  },
  invitationResend(invitationId: string) {
    return post(company.domain, company.invitationResend, { invitationId })
  },
  customerAccountsCount() {
    return get(company.domain, company.customerAccountsCount)
  },
  transactions(period: string) {
    return get(company.domain, company.transactions, { period })
  },
  outstandingInvoices({
    currentDate,
    period,
  }: {
    currentDate: string
    period?: string
  }) {
    return get(company.domain, company.outstandingInvoices, {
      period,
      currentDate,
    })
  },
  customerStat() {
    return get(company.domain, company.customerStat)
  },
  paymentTypes() {
    return get(company.domain, company.paymentTypes)
  },

  cardPricingPackage() {
    return get(company.domain, company.cardPricingPackage)
  },

  paymentPlan(planId: string) {
    return get<ILoanPaymentPlanModel>(company.domain, company.paymentPlan, {
      planId,
    })
  },

  savePricingPackageAgreement(data: any) {
    return post(company.domain, company.savePricingPackageAgreement, data)
  },
}
