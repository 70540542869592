import { Instance, types } from 'mobx-state-tree'
import { PricingProduct } from '../dictionaries/pricingProduct'
import { AdvancePaymentDay, PaymentDelayCode } from '../dictionaries/factoring'

export enum PRICING_PACKAGE_STATUS {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export type PricingPackageStatuses =
  typeof PRICING_PACKAGE_STATUS[keyof typeof PRICING_PACKAGE_STATUS]

export const LoanPrice = types.model('LoanPrice', {
  merchant: 0,
  maxAmountReceived: 0,
  advanceRate: 0,
  finalPayment: 0,
  merchantRebate: 0,
  merchantFeeAfterRebate: 0,
  maxAmountReceivedAfterRebate: 0,
  CustomerFees30: 0,
  CustomerFees6090: '',
  advancePaymentDay: types.maybe(
    types.enumeration<AdvancePaymentDay>(Object.values(AdvancePaymentDay)),
  ),
  finalPaymentDay: types.maybe(
    types.enumeration<PaymentDelayCode>(Object.values(PaymentDelayCode)),
  ),
})

export const LoanPricingPackage = types.model('LoanPricingPackage', {
  name: '',
  title: '',
  description: '',
  // TODO: VK: Add product field to existing records (LoC) and make it required // TODO: KK
  product: types.optional(
    types.enumeration<PricingProduct>(Object.values(PricingProduct)),
    PricingProduct.LineOfCredit,
  ),
  metadata: types.optional(LoanPrice, () => LoanPrice.create()),
  status: types.optional(
    types.enumeration<PRICING_PACKAGE_STATUS>(
      Object.values(PRICING_PACKAGE_STATUS),
    ),
    PRICING_PACKAGE_STATUS.ACTIVE,
  ),
  individual: types.optional(types.boolean, false),
  order: 0,
})

export interface ILoanPricingPackageModel
  extends Instance<typeof LoanPricingPackage> {}

export interface ILoanPriceModel extends Instance<typeof LoanPrice> {}
