import { CollectPaymentRequest, InvoiceItem } from '../dtos'
import { delete_, get, post } from '../helpers/caller'

export const invoices = {
  domain: 'invoices',
  saveInvoice(invoice: any) {
    return post(invoices.domain, invoices.invoice, invoice)
  },
  invoice(id: string) {
    return post(invoices.domain, invoices.invoice, { id })
  },
  invoiceGet(id: string) {
    return get(invoices.domain, invoices.invoice, { id })
  },
  allInvoices(params?: any) {
    return get<{
      totalCount: number
      items: InvoiceItem[]
    }>(invoices.domain, invoices.invoice, { ...params })
  },
  list(params?: any) {
    return get(invoices.domain, invoices.list, { ...params })
  },
  vendorsList(params?: any) {
    return get(invoices.domain, invoices.vendorsList, { ...params })
  },
  payablesInvoices(params?: any) {
    return get(invoices.domain, invoices.payablesInvoices, { ...params })
  },
  invoicesVendorsStatistics(params?: any) {
    return get(invoices.domain, invoices.invoicesVendorsStatistics, {
      ...params,
    })
  },
  listFromArray(params: any) {
    return get(invoices.domain, invoices.listFromArray, { ...params })
  },
  /***
   * Perform payment for invoice
   * @param invoiceIds id of invoices to pay
   * @param paymentMethod payment method card or ach
   * @param accountId selected account (bank account or card)
   * @param account_id id of chosen account in bank_accounts table
   * @returns {*}
   */

  collect(request: CollectPaymentRequest) {
    return post<{ result: string }>(invoices.domain, invoices.collect, request)
  },
  refundAmount(invoiceId: string, operationId: string, amount: number) {
    return post(invoices.domain, invoices.refundAmount, {
      invoiceId,
      operationId,
      amount,
    })
  },
  dismiss(params: any) {
    return post(invoices.domain, invoices.dismiss, { ...params })
  },
  cancel(id: string) {
    return post(invoices.domain, invoices.cancel, { id })
  },
  show(id: string | string[]) {
    return get(invoices.domain, invoices.show, { id })
  },
  showReceipt(id: string) {
    return get(invoices.domain, invoices.showReceipt, { id })
  },
  companyInvoices(id: string, companyId: string, invoicesIds?: string[]) {
    return get(invoices.domain, invoices.companyInvoices, {
      id,
      companyId,
      invoicesIds,
    })
  },
  paymentInfo(companyId: string, invoiceIds: string[]) {
    return post(invoices.domain, invoices.paymentInfo, {
      companyId,
      invoiceIds,
    })
  },
  getActionRequiredInvoices(params?: any) {
    return get(invoices.domain, invoices.getActionRequiredInvoices, {
      ...params,
    })
  },
  inviteSupplierSchedulePay(
    id: string[],
    userSub: string | undefined,
    paymentMethodId: string | undefined,
  ) {
    return post(invoices.domain, invoices.inviteSupplierSchedulePay, {
      id,
      userSub,
      paymentMethodId,
    })
  },
  approveInvoice(invoice: any) {
    return post(invoices.domain, invoices.approveInvoice, invoice)
  },
  rejectInvoice(id: string) {
    return get(invoices.domain, invoices.rejectInvoice, { id })
  },
  invoiceNotes(invoiceId: string) {
    return get(invoices.domain, invoices.invoiceNotes, { invoiceId })
  },
  invoiceNotesSave(note: { message: string; invoiceId: string }) {
    return post(invoices.domain, invoices.invoiceNotes, note)
  },
  invoiceNotesDelete(id: string) {
    return delete_(invoices.domain, invoices.invoiceNotes, { id })
  },
}
